// 工单统计配置表
export const WORKORDERLIST = [
  {
    type: 'todayAdd',
    titleText: '今日新增工单',
    orderNum: 'addedTodayNum',
    orderNumText: '单',
    bottomText: '昨日新增',
    bottomNum: 'addedYesterdayNum',
  },
  {
    type: 'todayDone',
    titleText: '今日完成工单',
    orderNum: 'doneTodayNum',
    orderNumText: '单',
    bottomText: '昨日完成',
    bottomNum: 'doneYesterdayNum',
  },
  {
    type: 'monthAdd',
    titleText: '本月新增工单',
    orderNum: 'addedMonthNum',
    orderNumText: '单',
    bottomText: '上月新增',
    bottomNum: 'addedLastMonthNum',
    rateText: '较上月',
    rateNum: 'addedGrowthRateLastMonth',
    iconFlag: true,
  },
  {
    type: 'monthDone',
    titleText: '本月完成工单',
    orderNum: 'doneMonthNum',
    orderNumText: '单',
    bottomText: '上月完成',
    bottomNum: 'doneLastMonthNum',
    rateText: '较上月',
    rateNum: 'doneGrowthRateLastMonth',
    iconFlag: true,
  },
  {
    type: 'totalDone',
    titleText: '工单完成总量',
    orderNum: 'totalDoneNum',
    orderNumText: '单',
    bottomText: '工单总量',
    bottomNum: 'totalNum',
    rateText: '完成率',
    rateNum: 'completionRate',
  },
  {
    type: 'monthDoneRate',
    titleText: '本月工单好评率',
    orderNum: 'positiveRatingMonthNum',
    orderNumText: '%',
    bottomText: '差评率',
    bottomNum: 'negativeRatingMonthNum',
  },
];

export const DATE_KEY_LIST = [
  'addedTodayNum',
  'addedYesterdayNum',
  'doneTodayNum',
  'doneYesterdayNum',
  'addedMonthNum',
  'addedLastMonthNum',
  'addedGrowthRateLastMonth',
  'doneMonthNum',
  'doneLastMonthNum',
  'doneGrowthRateLastMonth',
  'totalDoneNum',
  'totalNum',
  'completionRate',
  'positiveRatingMonthNum',
  'negativeRatingMonthNum',
];

export const PIE_SETTING = [
  {
    key: 'verySatisfied',
    name: '非常满意',
    color: '#4D9CF8',
  },
  {
    key: 'dissatisfied',
    name: '不满意',
    color: '#FF4141',
  },
  {
    key: 'satisfied',
    name: '满意',
    color: '#4CE4C2',
  },
  {
    key: 'veryDissatisfied',
    name: '非常不满意',
    color: '#47CBFF',
  },
  {
    key: 'ordinary',
    name: '一般',
    color: '#FFE340',
  },
];
