import server from '../request';

// 工单处理
export const todoWork = data => {
  return server.post('/xiaozi-xmb/wireless/saas/Workbench/backlog', data);
};

// 工单评价
export const evaluation = data => {
  return server.post('/xiaozi-xmb/wireless/saas/Workbench/evaluation', data);
};

// 运营统计
export const operationalStatistics = data => {
  return server.post('/xiaozi-xmb/wireless/saas/Workbench/operationalStatistics', data);
};

// 工单统计
export const workOrderStatistics = data => {
  return server.post('/xiaozi-xmb/wireless/saas/Workbench/workOrderStatistics', data);
};

// 协同办公列表
export const workbenchHandle = data => {
  return server.post('/yiwei-workflow/wireless/task/queryPageList', data);
};
