<template>
  <div class="synergy_container">
    <div v-auth="'workBench:synergism'" class="synergy_link" @click="$router.push({ name: 'ORDERWALL_SYNERGISM' })">
      <div class="text">全部待办</div>
      <i class="iconfont icon-neirong"></i>
    </div>
    <div class="synergy_content">
      <el-tabs v-model="activeName" @tab-click="getWorkbenchHandle">
        <el-tab-pane label="待办" name="AGENT"></el-tab-pane>
        <el-tab-pane label="我发起的" name="INITIATE"></el-tab-pane>
        <el-tab-pane label="已办" name="DONE"></el-tab-pane>
      </el-tabs>
      <div v-if="tabsData.length" class="order_ul">
        <div class="order_li" v-for="item in tabsData" :key="item.id">
          <div class="item typeName">{{ item.formDataMsg && item.formDataMsg.typeMsg && item.formDataMsg.typeMsg.name }}</div>
          <div class="item code">{{ item.formDataMsg.code }}</div>
          <div class="item status">
            <span class="card">{{ item.processStatusDesc }}</span>
          </div>
          <div class="item applicant">提交人：</div>
          <div class="item applicant_name">{{ item.preApproveUserName }}</div>
          <div class="item update_timer_label">提交时间：</div>
          <div class="item update_timer">{{ dateFormat(item.preApproveTime).format('YYYY-MM-DD HH:mm:ss') }}</div>
          <!-- <div class="item applicant">发起人：</div>
          <div class="item applicant_name">{{ item.submitUserName }}</div>
          <div class="item applicant_timer_label">发起时间：</div>
          <div class="item applicant_timer">{{ dateFormat(item.submitTime).format('YYYY-MM-DD HH:mm:ss') }}</div> -->
        </div>
      </div>
      <div v-else class="noData">
        <img :src="require('@/assets/imgs/noData.png')" />
        <div class="nodata_text">暂无数据</div>
      </div>
    </div>
  </div>
</template>
<script>
import { dateFormat } from '@/utils/dateFormat.js';
import { workbenchHandle } from '@/api/orderwall.js';
export default {
  data () {
    return {
      dateFormat,
      activeName: 'AGENT',
      tabsData: [],
    };
  },
  mounted () {
    this.getWorkbenchHandle();
  },
  methods: {
    getWorkbenchHandle () {
      this.tabsData = [];
      workbenchHandle({
        pageSize: 5,
        taskTypeEnum: this.activeName,
      }).then(({ body }) => {
        this.tabsData = [...body.list] || [];
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.synergy_container {
  padding-top: 20px;
  height: 100%;
  box-sizing: border-box;
  position: relative;

  .synergy_link {
    position: absolute;
    right: 0px;
    display: flex;
    padding: 0 6px 6px;
    margin-bottom: 15px;
    cursor: pointer;

    .text {

      font-size: 14px;
      font-weight: 400;
      color: rgba(15, 137, 255, 0.7);
      line-height: 14px;
    }

    .iconfont {
      line-height: 14px;
      color: rgba(15, 137, 255, 0.8);
    }
  }

  .synergy_content {
    padding: 19px 12px 0;
    /deep/ .el-tabs__item {
      color: #a7a9ad;
      &.is-active {
        font-weight: 600;
        color: #409EFF;
      }
    }

    /deep/ .el-tabs__nav-wrap::after {
      display: none;
    }

    /deep/ .el-tabs__item {
      height: 35px;
    }

    .order_ul {
      height: 170px;
      overflow-y: auto;

      .order_li {
        height: 46px;
        background: #FFFFFF;
        border-radius: 4px;
        border: 1px solid rgba(113, 121, 141, 0.16);
        margin-bottom: 12px;
        flex-flow: nowrap;
        padding-left: 12px;
        line-height: 46px;
        overflow: hidden;

        .item {
          font-size: 16px;
          font-weight: 400;
          color: rgba(36, 40, 50, 0.8);
          line-height: 46px;
          margin-right: 9px;
          white-space: nowrap;
          float: left;

          &.typeName {
            font-weight: 600;
          }

          &.code {
            color: rgba(36, 40, 50, 0.7);
          }

          &.status {
            .card {
              height: 20px;
              background: rgba(15, 137, 255, 0.07);
              border-radius: 2px;
              border: 1px solid rgba(15, 137, 255, 0.4);
              font-size: 12px;
              font-weight: 400;
              color: rgba(15, 137, 255, 0.8);
              line-height: 20px;
              padding: 4px 6px;
            }
          }
          &.update_timer {
            float: none;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }

    .noData {
      img {
        display: block;
        margin: auto;
        height: 120px;
      }

      .nodata_text {
        font-size: 16px;
        font-weight: 400;
        color: #5195EB;
        line-height: 16px;
        text-align: center;
      }
    }
  }
}
</style>
