<template>
  <div class="operation">
    <div class="condition">
      <el-date-picker class="timer condit" v-model="timePeriod" type="daterange" range-separator="-"
        value-format="timestamp" start-placeholder="开始日期" end-placeholder="结束日期" @change="init">
      </el-date-picker>
      <el-select class="types condit" v-model="value" disabled>
        <el-option value="1" label="工单趋势"></el-option>
      </el-select>
    </div>
    <div ref="chartsBox" class="charts_box"></div>
  </div>
</template>
<script>
import { throttle } from 'lodash';

import { operationalStatistics } from '@/api/orderwall.js';

import * as echarts from 'echarts';
const today = +new Date();
const beginDate = +new Date() - 1000 * 60 * 60 * 24 * 30;
export default {
  data () {
    return {
      operational: {},
      timePeriod: [beginDate, today],
      value: '1',
      myChart: null,
      throttled: null,
    };
  },
  mounted () {
    this.init();
    this.throttled = throttle(() => {
      this.myChart && this.myChart.resize();
    }, 500);
    window.addEventListener('resize', this.throttled);
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.throttled);
  },
  methods: {
    init () {
      this.getOperational().then(() => {
        this.initCharts();
      });
    },
    getOperational () {
      if (!this.timePeriod || !this.timePeriod.length) this.timePeriod = [beginDate, today];
      return operationalStatistics({ timePeriod: this.timePeriod }).then(({ body }) => {
        this.operational = body || {};
      });
    },
    initCharts () {
      this.myChart = echarts.init(this.$refs.chartsBox);
      let series = [];
      const xAxis = {
        type: 'category',
        boundaryGap: false,
        data: [],
      };
      const operationalKeys = Object.keys(this.operational);
      xAxis.data = operationalKeys.length ? this.operational[operationalKeys[0]].map(i => i.date).reverse() : [];
      operationalKeys.forEach(i => {
        series.push({
          name: i,
          type: 'line',
          smooth: true,
          data: this.operational[i].map(i => i.workOrderNum).reverse(),
        });
      });
      this.myChart.setOption({
        legend: {
          data: operationalKeys,
          left: 10,
          icon: 'circle',
          align: 'left',
          textStyle: {
            color: 'rgba(36, 40, 50, 0.7)',
            fontSize: '14px',
          },
        },
        color: ['#4CE4C2', '#0F89FF'],
        grid: {
          top: '20%',
          left: '3%',
          right: '3%',
          bottom: '12%',
        },
        tooltip: {
          trigger: 'axis',
        },
        xAxis,
        yAxis: {
          type: 'value',
        },
        series,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.operation {
  padding-top: 20px;
  height: 100%;
  min-height: 350px;

  .condition {
    padding-right: 12px;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 15px;

    .condit {
      font-size: 14px;
      font-weight: 400;
      color: rgba(15, 137, 255, 0.7);
      line-height: 14px;
    }

    /deep/ .el-range-separator {
      color: rgba(15, 137, 255, 0.8);
    }

    /deep/ .el-input__inner {
      border: none;
      color: rgba(15, 137, 255, 0.7);
    }

    /deep/ .el-select {
      width: 110px;
    }
    /deep/ .el-date-editor--daterange.el-input__inner {
      width: 250px;
    }

    /deep/ .el-range__icon {
      display: none;
    }
    /deep/ .el-input__icon {
      color: rgba(15, 137, 255, 0.7);
    }

    /deep/ .el-range-input {
      color: rgba(15, 137, 255, 0.7);
    }
  }

  .charts_box {
    height: calc(100% - 50px);
    padding: 0 12px;
  }
}
</style>
